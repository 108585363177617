import DefaultView from '../../../views/DefaultView';

const UserGenerator = () => import('../views/UserGenerator.vue');
const ListOrganizations = () => import('../views/ListOrganizations.vue');
const Info = () => import('../views/Info.vue');

export default [
  {
    path: '/dev-tools',
    name: 'devTools',
    component: DefaultView,
    children: [
      {
        path: 'account-create',
        name: 'devTools.account.create',
        component: UserGenerator,
        props: {
          enableRecaptcha: true,
        }
      },
      {
        path: 'list-organizations-etc',
        name: 'devTools.list.organizations',
        component: ListOrganizations,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'info',
        name: 'devTools.info',
        component: Info,
      },
    ],
  },
];
