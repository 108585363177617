export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    initialize({ dispatch }) {
      console.info('System initializing...');
      console.info('System initialized.');
    },
  },
};
